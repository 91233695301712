// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var TailwindMerge = require("tailwind-merge");
var JsxRuntime = require("react/jsx-runtime");
var MarkupHelpers = require("components/MarkupHelpers");

function MarkupHelpers$Container(props) {
  var __mobilePadding = props.mobilePadding;
  var mobilePadding = __mobilePadding !== undefined ? __mobilePadding : true;
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: TailwindMerge.twMerge([
                    "w-full mx-auto md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px] 2xl:max-w-[1320px]",
                    mobilePadding ? "px-4" : "md:px-4",
                    Core__Option.getOr(props.className, "")
                  ]),
              id: "container"
            });
}

var Container = {
  make: MarkupHelpers$Container
};

var ContentBox = {};

function MarkupHelpers$StandardLayout(props) {
  return JsxRuntime.jsx(MarkupHelpers$Container, {
              children: JsxRuntime.jsx(MarkupHelpers.ContentBox, {
                    paddingFull: true,
                    hasShadow: true,
                    children: Caml_option.some(props.children)
                  })
            });
}

var StandardLayout = {
  make: MarkupHelpers$StandardLayout
};

exports.Container = Container;
exports.ContentBox = ContentBox;
exports.StandardLayout = StandardLayout;
/* tailwind-merge Not a pure module */

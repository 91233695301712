// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var UIKit = require("../../../uikit/UIKit.bs.js");
var View__Jsx3 = require("../../../uikit/reason/helpers/View__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var JsxRuntime = require("react/jsx-runtime");

function MainLayout(props) {
  return JsxRuntime.jsx(View__Jsx3.make, {
              py: UIKit.responsive({
                    NAME: "px",
                    VAL: 20
                  }, {
                    NAME: "px",
                    VAL: 25
                  }, {
                    NAME: "px",
                    VAL: 30
                  }, {
                    NAME: "px",
                    VAL: 40
                  }, undefined, undefined, undefined),
              flexGrow: 1,
              children: Caml_option.some(props.children)
            });
}

var make = MainLayout;

exports.make = make;
/* View__Jsx3 Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var ErrorComponent = require("../Error/ErrorComponent.bs.js");
var JsxRuntime = require("react/jsx-runtime");

function CurrentUser__FetchErrorMessage(props) {
  return JsxRuntime.jsx(ErrorComponent.Large.make, {
              children: "There was an error loading your profile. Please try refreshing and contact support if the problem persists"
            });
}

var make = CurrentUser__FetchErrorMessage;

exports.make = make;
/* ErrorComponent Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Responsive = require("./reason/helpers/Responsive.bs.js");

var AgentInfo;

var AttributeList;

var Avatar;

var Autocomplete;

var Buttons;

var Card;

var Dialog;

var BaseDialog;

var Form;

var Heading;

var HighlightSection;

var Icon;

var InlineWarning;

var List;

var Loader;

var OrderSummary;

var Pagination;

var PaginationBox;

var PaymentForm;

var PriceAttribute;

var Responsive$1;

var SubscriptionPlan;

var $$Text;

var TextLabel;

var TipBox;

var TogglesGroup;

var VerifiedAgentCard;

var View;

var WarningSection;

var ContentBox;

var Chat;

var ConversationContextCard;

var Touchable;

var VerticalCard;

var IconCallout;

var HorizontalDivider;

var responsive = Responsive.make;

exports.AgentInfo = AgentInfo;
exports.AttributeList = AttributeList;
exports.Avatar = Avatar;
exports.Autocomplete = Autocomplete;
exports.Buttons = Buttons;
exports.Card = Card;
exports.Dialog = Dialog;
exports.BaseDialog = BaseDialog;
exports.Form = Form;
exports.Heading = Heading;
exports.HighlightSection = HighlightSection;
exports.Icon = Icon;
exports.InlineWarning = InlineWarning;
exports.List = List;
exports.Loader = Loader;
exports.OrderSummary = OrderSummary;
exports.Pagination = Pagination;
exports.PaginationBox = PaginationBox;
exports.PaymentForm = PaymentForm;
exports.PriceAttribute = PriceAttribute;
exports.Responsive = Responsive$1;
exports.SubscriptionPlan = SubscriptionPlan;
exports.$$Text = $$Text;
exports.TextLabel = TextLabel;
exports.TipBox = TipBox;
exports.TogglesGroup = TogglesGroup;
exports.VerifiedAgentCard = VerifiedAgentCard;
exports.View = View;
exports.WarningSection = WarningSection;
exports.ContentBox = ContentBox;
exports.Chat = Chat;
exports.ConversationContextCard = ConversationContextCard;
exports.Touchable = Touchable;
exports.VerticalCard = VerticalCard;
exports.IconCallout = IconCallout;
exports.HorizontalDivider = HorizontalDivider;
exports.responsive = responsive;
/* No side effect */
